export default {
  'activityPlanning.title': 'Activity Planner - {{role}}',
  'activityPlanning.description':
    'Neque, ligula elit, mi eu. Mauris, nunc elementum varius leo suspendisse. Sollicitudin mi lacus, diam vel in tincidunt. Consequat eu ultricies malesuada est id dignissim blandit neque donec. Elementum venenatis at sociis ipsum pretium tincidunt in etiam nunc. Nulla pretium purus aliquet tempor sem nunc ante. Elementum mattis ante mauris cras sit eget ullamcorper non. Curabitur urna feugiat pellentesque eget a. Proin tellus viverra aliquam tellus convallis. Ullamcorper sodales vitae morbi et convallis ut. Rutrum mi lobortis mauris a.',

  'add.project': 'Add project',
  'search.project': 'Project name',
  'allocationChange.success': 'Allocation type updated successfully',
  'activityPlanning.success': 'Activity planning saved successfully',
  'activityPlanning.deleteSuccess': 'Activity planning deleted successfully',
  'activityPlanning.delete': 'Delete activity planning',
  'activityPlanning.notes.title': 'Notes',
  'activityPlanning.notes.placeholder': 'Write your notes here...',
  'activityPlanning.notes.delete': 'Delete note',
  'activityPlanning.notes.success': 'Note saved successfully',
  'activityPlanning.notes.deleteSuccess': 'Note deleted successfully',
  'activityPlanning.notes.empty': 'This employee has not had any notes assigned',
  'employee.weeklyHours': '{{name}} - weekly hours',
  'activityPlanning.currentWeek': 'Current week ({{startDate}} - {{endDate}})',
  'activityPlanning.previousWeek': 'Last week ({{startDate}} - {{endDate}})',
  'activityPlanning.fully': 'Fully allocated',
  'activityPlanning.partially': 'Partially allocated',
  'week.pendingDaysOff': 'Pending day off request: {{numberOfDays}}',
  'week.approvedDaysOff': 'Approved day off request: {{numberOfDays}}',
  'week.nationalHolidays': 'National holiday: {{numberOfDays}}',
  'week.hours': '{{hours}}H / Week',
  'error.message': 'There has been an error',
  'filter.by': 'Filter by',
  'employee.type': 'Employee type',
  'employee.type.placeholder': 'Select Employee type',
};
