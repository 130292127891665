export default {
  'api.error': 'Something went wrong, please try again later',
  header: 'Header',
  'title.myWork': 'My Work',
  'title.admin': 'Admin',
  'title.contractor': 'Contractor',
  'title.contractor.pastDocuments': 'Past Documents',
  'title.holiday': 'Vacation Planner',
  'title.addRequest': 'Add request',
  'title.addVacation': 'Add vacation',
  'title.addTimeOff': 'Add time off',
  'title.addEvent': 'Add Event',
  'title.editTimeOff': 'Edit time off',
  'title.editRequest': 'Edit request',
  'title.editVacation': 'Edit vacation',
  'title.deleteRequest': 'Delete request',
  'title.deleteVacation': 'Delete vacation',
  'title.attachmentsRequest': 'Attach files',
  'title.employeeHandbook': 'Employee Handbook',
  'greetings.lynx': 'Lynx Solutions',
  'greetings.welcome': 'Welcome',
  'table.pagination.resultsPerPage': 'Results per page',
  'report.title.timeReport': 'Time Report',
  'report.title.summaryReport': 'Summary Report',
  'report.title.activityReport': 'Activity Report',
  'reset.invoice': 'Reset Invoice Hours',
  'save.invoice': 'Save Invoice Hours',
  'reset.managerHours': 'Reset Adjusted Hours',
  'save.managerHours': 'Save and Generate',
  'save.editedManagerHours': 'Edit Adjusted Hours',
  yes: 'yes',
  no: 'no',
  of: 'of',
  to: 'to',
  by: 'by',
  from: 'from',
  day_one: 'day',
  day_other: 'days',
  taken: 'taken',
  approve: 'approve',
  approved: 'approved',
  notApproved: 'Not approved yet',
  declined: 'declined',
  rejected: 'rejected',
  created: 'created',
  pending: 'pending',
  reject: 'reject',
  edit: 'edit',
  cancel: 'cancel',
  attachments: 'attachments',
  undo: 'undo',
  delete: 'delete',
  remove: 'remove',
  done: 'done',
  name: 'name',
  type: 'type',
  date: 'date',
  preview: 'Preview',
  download: 'Download',
  view: 'View',
  attention: 'Attention',
  description: 'Description',
  vacation: 'Vacation',
  today: 'today',
  month: 'month',
  week: 'week',
  save: 'save',
  submit: 'submit',
  left: 'left',
  manager: 'Manager',
  employee: 'Employee',
  managerOffOffice: 'Manager out off office',
  'type.absence': 'Type of absence',
  'attach.files': 'Attach additional files',
  'attach.file': 'Attach additional file',
  'attach.documents': 'Attach Documents',
  'open.month': 'Open month',
  'closed.month': 'Closed month',
  'reject.modal.title': 'Reject request',
  'reject.modal.textareaLabel': 'Please describe your rejection',
  'reject.modal.buttonLabel': 'Send rejection',
  'alert.hr.approveWithoutManager':
    "This request hasn't been accepted by the manager. Do you want to approve it anyway?",
  'delete.modal.subtitle': 'Are you sure you want to delete this request?',
  'delete.modal.subtitle.contractor': 'Are you sure you want to delete this vacation?',
  'error.required': 'This field is required',
  'error.message': 'There has been an error',
  'error.filePdf': 'File must be a pdf',
  'success.delete': 'The documents has been deleted',
  'reset.documents': 'Refetch Data',
  'message.pending.documents': 'Try refetching the documents',
  'projects.show': 'Show Projects',
  'info.show': 'Show more',
  'download.error': 'File download failed',
  'download.success': 'File downloaded successfully',
  'upload.success': 'File uploaded successfully',
  'page.notFound.title': 'Page not found',
  'page.notFound.redirect': 'Redirect to Dashboard',
  'page.forbidden.title': 'Forbidden',
  'select.employeeName': 'Employee name',
  'select.searchEmployee': 'Search employee',
  'holiday.modal.detailedList.title': 'Other days off',
  'holiday.select.vacationType': 'Please Select Vacation Type',
  'holiday.select.employeeName': 'Search or select name',
  'holiday.form.requiredMessage': 'This is required',
  'holiday.card.vacations': 'Vacation days',
  'holiday.card.usedVacation': 'Used vacation days',
  'holiday.card.vacationDaysPrevYear': "Last year's vacation days",
  'holiday.card.vacationDaysLeft': 'Vacation days left',
  'holiday.card.vacationDaysPending': 'Pending vacation days',
  'holiday.card.totalDaysOff': 'Total days off',
  'holiday.card.otherDaysOff': 'Other days off',
  'holiday.card.review': '{{vacationType}} {{vacationLength}} $t(day, {{count}})',
  'holiday.card.managerReview': '({{vacationType}} {{vacationLength}} $t(day, {{count}}))',
  'holiday.card.employeeReview': '{{vacationType}} ({{vacationLength}} $t(day, {{count}}))',
  'holiday.card.noInfo': 'No additional information',
  'holiday.requestsTitle.pending': 'Pending requests',
  'holiday.requestsTitle.past': 'Past requests',
  'holiday.requestsTitle.reviewed': 'Reviewed requests',
  'holiday.requestsTitle.contractor': 'My absences',
  'holiday.requestsSubtitle.pending': 'No pending requests',
  'holiday.requestsSubtitle.past': 'No past requests',
  'holiday.requestsSubtitle.reviewed': 'No reviewed requests',
  'holiday.requestsSubtitle.contractor': 'No past absences',
  'holiday.vacationDescription': 'Please add a short description and select a time-range for your vacation.',
  'holiday.editDescription': 'You can edit your request below.',
  'holiday.editDescription.contractor': 'You can edit your vacation below.',
  'holiday.administrativeTitle.title': 'Employee vacation overview',
  'holiday.administrativeTitle.monthlyReports': 'Monthly Time Reports',
  'holiday.administrativeTitle.dayOffRequest': 'Day Off Requests',
  'holiday.administrativeTitle.religiousCalendar': 'Catholic Holiday',
  'holiday.administrativeTitle.closeMonth': 'Close month',
  'holiday.administrativeSubtitle.closedMonth': 'Closed month',
  'holiday.administrativeSubtitle.openMonth': 'Open month',
  'holiday.administrativeSubtitle.dayOffRequest': 'No day off requests',
  'holiday.administrativeSubtitle.monthlyReports': 'No monthly requests',
  'holiday.administrativeSubtitle.noMonths': 'No months to be open/closed',
  'holiday.administrativeSubtitle.noCatholicHoliday': 'List not available for {{year}}',
  'holiday.outOfOfficeTitle.title': 'Out of office list',
  'holiday.outOfOfficeSubtitle.noOffList': 'All employees are available today',
  'holiday.myTime.attachFilesInfo': 'Please attach the proof documents corresponding to the selected day off type.',
  'holiday.manageTimeOff.addEventDescription':
    'In elementum massa amet, elementum quis nunc aliquam. At sapien proin faucibus dapibus iaculis vulputate.',
  'holiday.manageTimeOff.editEventDescription':
    'In elementum massa amet, elementum quis nunc aliquam. At sapien proin faucibus dapibus iaculis vulputate.',
  'holiday.orthodox': 'Orthodox Holiday',
  'holiday.catholic': 'Catholic Holiday',
  'holiday.change.religion.title': 'Change selected religion',
  'holiday.change.religion.success': 'Religion changed successfully.',
  'holiday.change.religion.question': 'Do you want to change selected religion?',
  'holiday.scheduler.contractor.vacationType': 'Vacation',
  'holiday.calendar.pendingVacation': '{{vacationType}} (pending)',
  'loading.data': 'Loading data...',
  'buttons.uploadInvoice': 'Upload Invoice',
  'buttons.reuploadInvoice': 'Re-Upload Invoice',
  'buttons.downloadAll': 'Download All',
  'buttons.viewAll': 'View All',
  'upload.invoice': 'Invoice uploaded',
  'data.none': 'No data available.',
  'monthName.january': 'January',
  'monthName.february': 'February',
  'monthName.march': 'March',
  'monthName.april': 'April',
  'monthName.may': 'May',
  'monthName.june': 'June',
  'monthName.july': 'July',
  'monthName.august': 'August',
  'monthName.september': 'September',
  'monthName.october': 'October',
  'monthName.november': 'November',
  'monthName.december': 'December',
  search: 'Search',
  projects: 'Projects',
  empty: 'Empty',
  admit: 'Admit',
  'download.unavailable': 'Download unavailable',
  'date.return.today': 'Return to current date',
  'event.holidayRequest.successful': 'Submitted holiday request.',
  'event.holidayRequest.editedSuccessful': 'Holiday request edited successfully',
  'event.holidayRequest.deleteSuccessful': 'Holiday request deleted successfully',
  'event.vacation.successful': 'Submitted vacation',
  'event.vacation.editedSuccessful': 'Vacation edited successfully',
  'event.vacation.deleteSuccessful': 'Vacation deleted successfully',
  'event.holidayRequest.approved': 'Request approved',
  'error.date.required': 'Select Date',
  sort: 'Sort by',
  'sort.asc': 'Starting date (asc)',
  'sort.desc': 'Starting date (desc)',
  'sort.start.date.desc': 'Request Date (newest)',
  'sort.start.date.asc': 'Request Date (oldest)',
  'sort.absence.date.desc': 'Absence Date (newest)',
  'sort.absence.date.asc': 'Absence Date (oldest)',
  'sort.time.off.date.desc': 'Time Off Date (newest)',
  'sort.time.off.date.asc': 'Time Off Date (oldest)',
  'sort.partially.allocated': 'Partially allocated',
  'sort.fully.allocated': 'Fully allocated',
  'sort.resource.type': 'Resource type',
  pageOf: 'Page {{currentPage}} of {{totalPages}}',
  approvedBy: 'Approved by {{name}}',
  notApprovedBy: 'Not yet approved by {{name}}',
  managerOffByName: '{{name}} out of office',
  exchangeRate: 'Exchange Rate',
  'exchangeRate.message': 'Please create your invoice in RON, but include the exchange rate at the bottom as a note.',
  user: 'User',
  password: 'Password',
  'close.month.title': 'Close {{month}}',
  'close.month.question': 'Are you sure you would like to close this month?',
  'open.month.title': 'Open {{month}}',
  'open.month.question': 'This action is highly critical!\nAre you sure you would like to open this month?',
  'open.month.success': 'Month opened successfully.',
  'close.month.success': 'Month closed successfully.',
  clear: 'Clear',
  'autocomplete.noOptions': 'No options',
  'missing.requests.description': 'Some day off requests have no generated documents. Please regenerate them now.',
  'missing.requests.button': 'Regenerate Documents',
  'missing.requests.ongoing': 'Documents are regenerating',
  'missing.requests.toast': 'Regenerate process started.',
  'out.of.office.until': 'until',
};
