import { SingleSelectItem } from '@/components/Inputs';
import i18n from '@/config/i18n';
import { MonthNames } from '@/types/utils';
import { addDays } from 'date-fns';

export const FETCH_REQUEST_OPTIONS: RequestInit = {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
};

export const monthNames: MonthNames = {
  january: i18n.t('monthName.january'),
  february: i18n.t('monthName.february'),
  march: i18n.t('monthName.march'),
  april: i18n.t('monthName.april'),
  may: i18n.t('monthName.may'),
  june: i18n.t('monthName.june'),
  july: i18n.t('monthName.july'),
  august: i18n.t('monthName.august'),
  september: i18n.t('monthName.september'),
  october: i18n.t('monthName.october'),
  november: i18n.t('monthName.november'),
  december: i18n.t('monthName.december'),
};

export const minDate = new Date('2003-01-01');
export const today = new Date();
export const nextYear = new Date((today.getFullYear() + 1).toString() + '-12-31');
export const nextWorkDay =
  today.getDay() === 5 ? addDays(today, 3) : today.getDay() === 6 ? addDays(today, 2) : addDays(today, 1);

export const BANK_HOLIDAY_ID = 99;

export const sorts: SingleSelectItem[] = [
  {
    id: 0,
    name: 'desc',
  },
  {
    id: 1,
    name: 'asc',
  },
];

export const OrderByDirection = {
  Ascending: 'asc',
  Descending: 'desc',
};

export const ACTIVITY_PLANNER_EMPLOYEE_TYPES = {
  Employee: { name: 'Employee', id: 0 },
  Contractor: { name: 'Contractor', id: 1 },
} as const;

export const minimumEntriesForPagination = 10;
export const minimumEntriesForMyTimePendingPagination = 5;
export const minimumEntriesForMyTimeReviewedPagination = 10;

export const CurrencySymbols = {
  RON: 'RON',
  EUR: '€',
} as const;

export const HR_EMAIL = 'HR@lynxsolutions.eu';

export const LYNX_SHAREPOINT = 'https://lynxsolution.sharepoint.com/';

export const LYNX_MAILING_INFO =
  'Corunca, Str Ropo, Nr.6. Recipient Lynx Solutions, Contact Person Szasz Levente, phone number: 0728905885';

export const LEARNING_PLATFORMS = [
  {
    name: 'Laracast',
    user: 'subscription@lynxsolutions.eu',
    password: 'AubG%asE&vnq9u\\k',
  },
  {
    name: 'Pluralsight',
    user: 'subscription@lynxsolutions.eu',
    password: 'yQ=RydK?p8tB9t!a',
  },
];

export const ISO_CERTIFICATIONS_LINK =
  'https://lynxsolution.sharepoint.com/ISO/Forms/AllItems.aspx?id=/ISO/iSO%2027001&#38;viewid=c0adce3e-ac1a-4ad2-874a-7da616fc8f31';

export const ISO_DOCUMENTS = {
  overview:
    'https://lynxsolution.sharepoint.com/:f:/r/ISO/iSO%2027001/1.%20overview%20document%20-%20Information%20Security%20Policy?csf=1&web=1&e=JKGaAK',
  main: 'https://lynxsolution.sharepoint.com/:f:/r/ISO/iSO%2027001/2.%20main%20document%20-%20Table%20A.1%20Information%20Security%20Controls?csf=1&web=1&e=J7pWnD',
  supporting:
    'https://lynxsolution.sharepoint.com/:f:/r/ISO/iSO%2027001/3.%20supporting%20documents%20-%20Polices,%20Rules%20and%20other?csf=1&web=1&e=xty8kZ',
  iSO27001: 'https://lynxsolution.sharepoint.com/:f:/r/ISO/iSO%2027001?csf=1&web=1&e=zmlSKx',
  iSO9001: 'https://lynxsolution.sharepoint.com/:f:/r/ISO/iSO%209001?csf=1&web=1&e=74fI7H',
};

export const LYNX_BOOK_MASSAGE_LINK = 'https://outlook.office365.com/book/Massage@lynxsolutions.eu/';

export const HANDBOOK_RESOURCES = {
  common: {
    organizationalStructure:
      'https://lynxsolution.sharepoint.com/:b:/g/EfAxlf89umNFuQhuQE1ySugBs4QwVaESkvwjaxrSf660Pg?e=HusGFE',
    onboarding: 'https://lynxsolution.sharepoint.com/:b:/g/EfGb2FpEJxNGqL585rddrjwBXXrdS3H-oupNIfaYnBBPGA?e=vtHSxX',
    holidays: 'https://lynxsolution.sharepoint.com/:b:/g/Ec-qDx92OANCvB9qoVa32L8B3zFDKeSBkygj3WyLJKKcOA?e=5KIqlL',
    documents: 'https://lynxsolution.sharepoint.com/:b:/g/EQvphW8fKytNktO7p3rcp_sB3bXjSN-2Ht2NN7icKnOqwg?e=cSDAgK',
    internalBusiness:
      'https://lynxsolution.sharepoint.com/:b:/g/EbEt-v3Se_1KjYMivBMIeXABCtE2od0yAkuqTMDm4QyejA?e=R9bcM0',
    externalBusiness:
      'https://lynxsolution.sharepoint.com/:b:/g/EYz7gyQjkH1Jnu96cQV3RZkB6AEp6gQJmLOV420QgQXugg?e=1ifT0a',
    internalCommunication:
      'https://lynxsolution.sharepoint.com/:b:/g/EVC5P01mMWJIlpxunaSPQ5QBNVT66JvSy_d7kfvS1cHVCg?e=bdKfAI',
    employeeBenefits:
      'https://lynxsolution.sharepoint.com/:b:/g/EeYHDqaeJ_dInslESiLvqV8Bfbkd1f9Lb-84e93_Pf0V1A?e=xtmXZW',
    employeeEvaluation:
      'https://lynxsolution.sharepoint.com/:b:/g/EWNjSLzDTwNOhGaBiyJzsgsBHn_-ccpwYCze1KmvbZYG1Q?e=XEzTfK',
    discrimination: 'https://lynxsolution.sharepoint.com/:b:/g/EUhhGYtDIIFEse4uSDla5WYBlqRVGpEr3L9rjhjP3P6DEg?e=wP5qrG',
    confidentiality:
      'https://lynxsolution.sharepoint.com/:b:/g/EbzsptJ2SvhFnJGajzKABPkBKZ75vxb1vfMyYSrG-9tItA?e=ysIw1F',
    nonCompete: 'https://lynxsolution.sharepoint.com/:b:/g/EaTAWWRkmf9NlS10T2fewqMBj_GNuN4EJfpDQs5lOibO3w?e=joSd8I',
    workFromHome: 'https://lynxsolution.sharepoint.com/:b:/g/EckwnXb5nSFIj_tr6ZrfqIEBgI8NjVlk22wmPsUFv4DqWQ?e=hJyyj0',
    jobAndOffer: 'https://lynxsolution.sharepoint.com/:b:/g/Ee8LkGNjx81Fph8wmkzrd28BQUGowzIh_MR3oD_SbcOg3g?e=ujTQpl',
    events: 'https://lynxsolution.sharepoint.com/:b:/g/ETqXF58K-kJFjeXtsleGLngB09HDpH7lF6rOyZtGoPRsWw?e=XJdE7D',
    referral: 'https://lynxsolution.sharepoint.com/:b:/g/EfS-A1FMWRpPi2dpbAoPhCEBYbwHdL-dn5oYPkNkCqDwSg?e=esVMSE',
    internship: 'https://lynxsolution.sharepoint.com/:b:/g/ESG83JcqKcpFuQ6LBgUh8zoB1tQf8-oOO7aEimXS_ybwWg?e=5ETg1S',
    summerPractice: 'https://lynxsolution.sharepoint.com/:b:/g/ET-kHug-FGxDjHZ2nAUFGVIBEl3SDy1vfHUQdP3aJNX3CQ?e=T1cLOP',
    devices: 'https://lynxsolution.sharepoint.com/:b:/g/ESnGPKRUFc5BgyUYwOrqazIBACzOPgr6TkB05hz3glHXFg?e=falazi',
    offboarding: 'https://lynxsolution.sharepoint.com/:b:/g/EYwgiirxw7BBvWTZ_EQ59okBPzg1p4VUabw7oZZmBMlFbA?e=eFPA17',
  },
  administrative: [
    {
      name: 'PO-02: Procedura de delegație internă',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EbEt-v3Se_1KjYMivBMIeXABCtE2od0yAkuqTMDm4QyejA?e=R9bcM0',
    },
    {
      name: 'PO-03: Procedura de delegație externă',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EYz7gyQjkH1Jnu96cQV3RZkB6AEp6gQJmLOV420QgQXugg?e=1ifT0a',
    },
    {
      name: 'PO-15: Procedura de refferal program',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EfS-A1FMWRpPi2dpbAoPhCEBYbwHdL-dn5oYPkNkCqDwSg?e=esVMSE',
    },
    {
      name: 'PO-16: Procedura de desfășurare a activității în regim de telemuncă',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EckwnXb5nSFIj_tr6ZrfqIEBgI8NjVlk22wmPsUFv4DqWQ?e=hJyyj0',
    },
    {
      name: 'PO-19: Procedura actualizare a datelor',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EQvphW8fKytNktO7p3rcp_sB3bXjSN-2Ht2NN7icKnOqwg?e=cSDAgK',
    },
    {
      name: 'PO-23: Procedura organizare a evenimentelor',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/ETqXF58K-kJFjeXtsleGLngB09HDpH7lF6rOyZtGoPRsWw?e=XJdE7D',
    },
    {
      name: 'PO-27: Procedura de maternitate',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EZKHpY43J2tFpnViY8C93rgBqn1O8vgfEV9NdssH0nmorw?e=TdAzXj',
    },
    {
      name: 'PO-28: Procedura solicitare conventii AJOFM',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EYTKG2_LA4hDoUV9jNxgTDsB7MME9gexw1IOt3m_IqueXA?e=7zaABB',
    },
    {
      name: 'PO-29: Procedura structurii organizaționale',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EfAxlf89umNFuQhuQE1ySugBs4QwVaESkvwjaxrSf660Pg?e=HusGFE',
    },
    {
      name: 'PO-30: Procedura de logare a orelor',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/ESK6EZi86NRMuIeIFCzJk_YBzHzCdNbXyEiIHG_MndekxA?e=q1l5xo',
    },
    {
      name: 'PO-31: Procedura de solicitare zile libere LMP',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/Ec-qDx92OANCvB9qoVa32L8B3zFDKeSBkygj3WyLJKKcOA?e=5KIqlL',
    },
  ],
  recruitment: [
    {
      name: 'PO-05: Procedura vacantare a poziției',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EcCvyWyuOwNPo7toA-hKCQ4BUoTJnhYdKMus8btQLVjc5w?e=b63dmy',
    },
    {
      name: 'PO-06: Procedura de selectie si recrutare',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EdDpZ56tIpVMpVOhzl6wzQ4BhFSdIGsu2zHMUEFOKQxpNw?e=r0aPhC',
    },
    {
      name: 'PO-07: Procedura de Ofertare si Angajare',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/Ee8LkGNjx81Fph8wmkzrd28BQUGowzIh_MR3oD_SbcOg3g?e=ujTQpl',
    },
    {
      name: 'PO-08: Procedura de Onboarding',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EfGb2FpEJxNGqL585rddrjwBXXrdS3H-oupNIfaYnBBPGA?e=vtHSxX',
    },
    {
      name: 'PO-11: Procedura de Offboarding',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EYwgiirxw7BBvWTZ_EQ59okBPzg1p4VUabw7oZZmBMlFbA?e=eFPA17',
    },
    {
      name: 'PO-18: Procedura utilizare Opencats',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EduBI-lyKt5CsRhj4slZe0sBeGH5SDAmyXPjqW33svibLQ?e=wM8YFj',
    },
    {
      name: 'PO-24: Procedura desfasurarea practicii obligatorie',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/ET-kHug-FGxDjHZ2nAUFGVIBEl3SDy1vfHUQdP3aJNX3CQ?e=T1cLOP',
    },
    {
      name: 'PO-25: Procedura desfasurare a internshipului',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/ESG83JcqKcpFuQ6LBgUh8zoB1tQf8-oOO7aEimXS_ybwWg?e=5ETg1S',
    },
  ],
  performance: [
    {
      name: 'PO-09: Procedura de evaluare a performantelor',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EWNjSLzDTwNOhGaBiyJzsgsBHn_-ccpwYCze1KmvbZYG1Q?e=XEzTfK',
    },
    {
      name: 'PO-10: Procedura de negociere salarială',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/Ea44f9tpBq9MrRczDp7USywBJnW6mK2R1OYYVanZr9fhig?e=xp6hZc',
    },
  ],
  employeeBenefits: [
    {
      name: 'PO-04: Procedura formare profesională',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/ETWCFHicjhhJoHWp1YslrQEBGmvaN1TweSWIbnC0ID5wqA?e=NNGE0T',
    },
    {
      name: 'PO-17: Procedura de compensatii si beneficii',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EeYHDqaeJ_dInslESiLvqV8Bfbkd1f9Lb-84e93_Pf0V1A?e=xtmXZW',
    },
  ],
  internalCommunication: [
    {
      name: 'PO-12: Procedura transmitere documente intern',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EViX_bEm-r5Mp2XXqKXkBLIBvx0d5hInfAvebcJ9dkPuOQ?e=C3FNWi',
    },
    {
      name: 'PO-13: Procedura transmitere documente extern',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/ETkM-t34rw5JlDrqVR_1xWUBSfFijjIBhzX5PeJUdo-eiA?e=WIVad9',
    },
    {
      name: 'PO-14: Procedura de comunicare internă',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EVC5P01mMWJIlpxunaSPQ5QBNVT66JvSy_d7kfvS1cHVCg?e=bdKfAI',
    },
    {
      name: 'PO-26: Procedura utilizare a intrumentelor de lucru',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/ESnGPKRUFc5BgyUYwOrqazIBACzOPgr6TkB05hz3glHXFg?e=falazi',
    },
  ],
  policiesAndProcesses: [
    {
      name: 'PO-20: Procedura de etică și conduită profesională',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/Ea6DB8qAbK1EophwI1LMbxwBCrkNwBZvB4j-D9yqXgyE9A?e=Rt2peZ',
    },
    {
      name: 'PO-21: Procedura neconcurenta',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EaTAWWRkmf9NlS10T2fewqMBj_GNuN4EJfpDQs5lOibO3w?e=joSd8I',
    },
    {
      name: 'PO-22: Procedura discriminare',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EUhhGYtDIIFEse4uSDla5WYBlqRVGpEr3L9rjhjP3P6DEg?e=wP5qrG',
    },
    {
      name: 'PO-01: Procedura de confidentialitate',
      link: 'https://lynxsolution.sharepoint.com/:b:/g/EbzsptJ2SvhFnJGajzKABPkBKZ75vxb1vfMyYSrG-9tItA?e=ysIw1F',
    },
    {
      name: 'Procedura GDPR/data protection',
      link: 'https://lynxsolution.sharepoint.com/:f:/g/EiyB0CUgdG9IqM5u5_N-fa8BOplFW-7zvEFcAB7qfeI8gQ?e=e02cU1',
    },
  ],
};
export const weekWorkHours = 40;

export const currencies: SingleSelectItem[] = [
  { id: 0, name: 'EUR' },
  { id: 1, name: 'RON' },
];

export const PRODUCTION_URL = 'https://lmp-production.web-staging.eu';
export const STAGING_URL = 'https://lynxmanagementportal.web-staging.eu';
