import { YearCalendar as MuiYearPicker, YearCalendarProps as MuiYearPickerProps } from '@mui/x-date-pickers';
import { SxProps } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import clsx from 'clsx';
import React, { useState } from 'react';
import { addYears, subYears } from 'date-fns';

import DateIcon from '@/assets/icon-calendar.svg';
import ArrowDown from '@/assets/caret_down.svg';
import ArrowLeft from '@/assets/chevron_left.svg';
import ArrowRight from '@/assets/chevron_right.svg';
import { Button } from '@/components/Inputs';
import { useTranslation } from 'react-i18next';

export type YearPickerProps = {
  disablePastYear?: boolean;
  disableFutureYear?: boolean;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  placement?: 'right' | 'left';
  showTodayButton?: boolean;
} & MuiYearPickerProps<Date>;

export const YearPicker = React.forwardRef<HTMLDivElement, YearPickerProps>(
  ({ className = '', placement = 'right', disabled = false, showTodayButton, ...props }, ref) => {
    const { value, onChange, disablePastYear, disableFutureYear, maxDate, minDate } = props;
    const [isOpen, setOpen] = useState<boolean>(false);
    const { t } = useTranslation('common');

    const handleClickAway = () => {
      setOpen(false);
    };

    const handleYearIncrement = () => {
      if (
        onChange &&
        value &&
        ((!disableFutureYear && !maxDate) || (maxDate && maxDate.getFullYear() > value.getFullYear()))
      )
        value !== null && onChange(addYears(value, 1));
    };

    const handleYearDecrement = () => {
      if (
        onChange &&
        value &&
        ((!disablePastYear && !minDate) || (minDate && minDate.getFullYear() < value.getFullYear()))
      )
        value !== null && onChange(subYears(value, 1));
    };

    const handleOnChange = (newValue: Date) => {
      onChange && onChange(newValue);
      setOpen(!isOpen);
    };

    const sxStyle: SxProps = {
      '& .PrivatePickersYear-root': {
        borderRadius: 'inherit',
        margin: 0,
        flexBasis: '25%',
        height: '40px',
        padding: '12px 10px 12px 10px',
        fontFamily: 'Ubuntu',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16.8px',
      },
      '& .Mui-selected, .Mui-selected:hover, .Mui-selected:active, .Mui-selected:focus': {
        backgroundColor: '#E9ECF5 !important',
        color: 'black !important',
      },
    };

    return (
      <div className="relative self-auto md:self-center" ref={ref}>
        <div className="relative flex w-fit cursor-pointer items-center" onClick={() => setOpen(!isOpen)}>
          <img src={DateIcon} alt="date" />
          <span className="px-2">{value && `${value.getFullYear()}`}</span>
          <img src={ArrowDown} alt="open date dropdown" className="w-2.5 h-3" />
        </div>
        {isOpen && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div
              className={clsx(
                'w-66 absolute z-10 mx-4 rounded-md bg-white shadow-md',
                placement === 'right' && '-left-1/4',
                placement === 'left' && '-right-1/4',
              )}
            >
              <div className="flex items-center justify-between bg-light_blue px-4 py-4">
                <img
                  className={
                    disabled || disablePastYear || (minDate && value && minDate?.getFullYear() >= value?.getFullYear())
                      ? 'opacity-40'
                      : 'cursor-pointer'
                  }
                  src={ArrowLeft}
                  onClick={handleYearDecrement}
                  alt="date"
                />
                <span className="rounded-sm border-[1px] border-solid border-secondary px-4">
                  {value ? value.getFullYear() : new Date().getFullYear()}
                </span>
                <img
                  className={
                    disabled ||
                    disableFutureYear ||
                    (maxDate && value && maxDate?.getFullYear() <= value?.getFullYear())
                      ? 'opacity-40'
                      : 'cursor-pointer'
                  }
                  src={ArrowRight}
                  onClick={handleYearIncrement}
                  alt="date"
                />
              </div>
              <MuiYearPicker
                disabled={disabled}
                {...props}
                className={clsx('border-sm m-0 grid grid-cols-3 w-max text-secondary', className)}
                onChange={handleOnChange}
                sx={sxStyle}
              />
              {showTodayButton && (
                <div className="flex w-full bg-light_blue hover:bg-secondaryLight hover:bg-opacity-20 duration-150">
                  <Button
                    variant="text"
                    color="secondary"
                    className="hover:bg-secondaryLight hover:bg-opacity-20 flex w-full justify-center items-center rounded-none duration-150"
                    onClick={() => handleOnChange(new Date())}
                  >
                    {t('today')}
                  </Button>
                </div>
              )}
            </div>
          </ClickAwayListener>
        )}
      </div>
    );
  },
);
