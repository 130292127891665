import { IdAndNameItem, ValueOf, Prettify, ResponseMeta } from '@/types/utils';
import { FileListProps } from './myTimeTypes';
import { IEmployeeItem } from './manageTimeOffTypes';
import { EmployeeTypes } from '@/features/authorization';
import i18n from '@/config/i18n';

export interface ReviewedRequests {
  createdByHr: IEmployeeItem | null;
  id: number;
  view?: View;
  holidayLength: number;
  status?: TRequestStatus;
  dateFrom: string;
  dateTo: string;
  approvedManager?: string;
  approvedHr?: string;
  managerDecision?: null | RequestDecision;
  hrDecision?: null | RequestDecision;
  files: FileListProps[];
  type: IVacationTypes;
}

export interface PendingRequests {
  id: number;
  holidayLength: number;
  status: TRequestStatus;
  dateFrom: string;
  dateTo: string;
  projects: IdAndNameItem[];
  description?: string;
  files: FileListProps[];
  type: IVacationTypes;
}

export type View = 'hr' | 'manager' | 'employee';

export const Holiday = {
  Vacation: 'vacation',
  NonPaid: 'non paid',
  Medical: 'medical',
  Maternity: 'maternity',
  Rest: 'rest',
  Rest4Hours: 'rest 4 hours',
  ChildBirth: 'child birth',
  ChildCare: 'child care',
  Marriage: 'marriage',
  Death: 'death',
  BloodDonation: 'blood donation',
  UnpaidVacation: 'unpaid vacation',
  TechnicalUnemployment: 'technical unemployment',
} as const;

export type THoliday = ValueOf<typeof Holiday>;

export const RequestStatus = {
  Pending: 'pending',
  ApprovedManager: 'approvedManager',
  ApprovedHr: 'approvedHr',
  Declined: 'declined',
} as const;

export type TRequestStatus = ValueOf<typeof RequestStatus>;

export interface VacationDetails {
  stats: VacationStats;
  daysOff: VacationDaysOff[];
}

export interface VacationStats {
  vacationDays: number;
  vacationDaysPrevYear: number;
  usedVacationDays: number;
  remainingVacationDays: number;
  otherDaysOff: number;
  pendingVacationDays: number;
}

export type VacationDaysOff = {
  name: string;
  value: number;
};

export type VacationDaysOffResponse = {
  name: string;
  count: number;
};

export interface VacationDetailsResponse {
  stats: VacationStats;
  daysOff: VacationDaysOffResponse[];
}

type RequestsMetaNested = {
  links: RequestsMetaLinks[];
  path: string;
} & ResponseMeta;

export type RequestsMeta = Prettify<RequestsMetaNested>;

export interface RequestsMetaLinks {
  url: string;
  label: string;
  active: boolean;
}

export interface RequestsLinks {
  first: string;
  last: string;
  prev: null;
  next: null;
}

export interface RequestDecision {
  employee: IdAndNameItem;
  status: TInitialRequestStatus;
  date: string;
}

export type TInitialRequestStatus = ValueOf<typeof InitialRequestStatus>;

export const InitialRequestStatus = {
  Pending: 'pending',
  Approved: 'approved',
  Declined: 'rejected',
  ApprovedManager: 'approvedManager',
  ApprovedHr: 'approvedHr',
} as const;

export interface IntervalRequest {
  requestId?: number;
  vacationId?: number;
  employeeId?: number;
  typeId?: number;
  startDate: string;
  endDate: string;
}

export interface RequestEditFormData {
  requestId?: number;
  vacationType: IVacationTypes;
  description: string;
  fromDate: Date;
  toDate: Date;
}

export interface VacationType {
  id: number;
  name: THoliday;
}

export interface RequestContractorFormData {
  requestId?: number;
  description: string;
  fromDate: Date;
  toDate: Date;
}

export type FormVacationType = IdAndNameItem & {
  isHalfDayRequest: boolean;
};

export interface RequestFormData {
  requestId?: number;
  vacationType: FormVacationType;
  description: string;
  fromDate: Date;
  toDate: Date;
}
export interface EventFormData {
  requestId?: number;
  employee: IdAndNameItem;
  vacationType: FormVacationType;
  description: string;
  fromDate: Date;
  toDate: Date;
}

export const VacationTypes = {
  Vacation: 'Rest',
  Medical: 'Medical',
  NationalHoliday: 'National holiday',
  Obligation: 'Obligation',
  Delegation: 'Delegation',
  UnpaidLeave: 'Unpaid leave',
  Morning: 'Morning',
  Afternoon: 'Afternoon',
} as const;

export const ReligionTypes = {
  Orthodox: 'Orthodox',
  Catholic: 'Catholic',
} as const;

export const R = typeof ReligionTypes;

export interface ReligionProfile {
  religion: Religion;
  canModifyReligion: boolean;
}

export interface Religion {
  id: number;
  name: (typeof ReligionTypes)[keyof typeof ReligionTypes];
}

export interface ReligionChangeRequest {
  religionId: number;
}
export interface ErrorResponse {
  status: number;
  data: DataErrorResponse;
}

export interface DataErrorResponse {
  message: string;
}

export interface IVacationTypes {
  id: number;
  name: string;
  description: string;
  romanianName: string;
  attachmentNeeded: boolean;
  retroactiveCreationAllowed: boolean;
  canBeRejected: boolean;
  isHalfDayRequest: boolean;
  isBeforeNoon: boolean;
  color: string;
}

export const IVacationTypesFlags = {
  romanianName: 'romanianName',
  attachmentNeeded: 'attachmentNeeded',
  retroactiveCreationAllowed: 'retroactiveCreationAllowed',
  canBeRejected: 'canBeRejected',
  isHalfDayRequest: 'isHalfDayRequest',
} as const;

export interface Interval {
  interval: number;
}

export type TVacationTypesFlags = ValueOf<typeof IVacationTypesFlags>;

export type EmployeeRequest = {
  type?: EmployeeTypes;
};

export const ReportType = {
  monthlyReports: 'monthlyReports',
  dayOffRequest: 'dayOffRequest',
} as const;

export type TReportType = ValueOf<typeof ReportType>;

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export type SortOption = {
  label: string;
  sort: string;
  order: string;
};

export const SortType = {
  startDateAsc: { label: i18n.t('sort.start.date.asc'), sort: 'created_at', order: 'asc' },
  startDateDesc: { label: i18n.t('sort.start.date.desc'), sort: 'created_at', order: 'desc' },
  absenceDateAsc: { label: i18n.t('sort.absence.date.asc'), sort: 'start_date', order: 'asc' },
  absenceDateDesc: { label: i18n.t('sort.absence.date.desc'), sort: 'start_date', order: 'desc' },
  timeOffAsc: { label: i18n.t('sort.time.off.date.asc'), sort: 'start_date', order: 'asc' },
  timeOffDesc: { label: i18n.t('sort.time.off.date.desc'), sort: 'start_date', order: 'desc' },
} as const;

export type OutOfOfficeEmloyee = IdAndNameItem & {
  until: string;
};
