import { RoutesCollection, TRouteObjectWithName } from '@/config/routing';
import { Authorization, ROLES } from '@/features/authorization';
import { lazyImport } from '@/lib/lazyImport';
import { Navigate, Outlet } from 'react-router-dom';

const { MonthlyReports } = lazyImport(() => import('./MonthlyReports'), 'MonthlyReports');
const { OverdueInvoices } = lazyImport(() => import('./OverdueInvoices'), 'OverdueInvoices');
const { AllInvoices } = lazyImport(() => import('./AllInvoices'), 'AllInvoices');
const { Profitability } = lazyImport(() => import('./Profitability'), 'Profitability');
const { PartiallyAllocated } = lazyImport(() => import('./PartiallyAllocated'), 'PartiallyAllocated');

export const reportsRoutes: TRouteObjectWithName[] = [
  {
    path: '/reports/overdue-invoices',
    element: (
      <Authorization
        allowedRoles={[ROLES.ADMIN]}
        allowedEmployeeTypes={[]}
        forbiddenFallback={<Navigate to={RoutesCollection.ForbiddenPage} />}
      >
        <OverdueInvoices />
      </Authorization>
    ),
    name: 'menu.reports.overdue',
    allowedRoles: [ROLES.ADMIN],
  },
  {
    path: '/reports/monthly',
    element: (
      <Authorization
        allowedRoles={[ROLES.ADMIN]}
        allowedEmployeeTypes={[]}
        forbiddenFallback={<Navigate to={RoutesCollection.ForbiddenPage} />}
      >
        <MonthlyReports />
      </Authorization>
    ),
    name: 'menu.reports.monthly',
    allowedRoles: [ROLES.ADMIN],
  },
  {
    path: '/reports/all-invoices',
    element: (
      <Authorization
        allowedRoles={[ROLES.ADMIN]}
        allowedEmployeeTypes={[]}
        forbiddenFallback={<Navigate to={RoutesCollection.ForbiddenPage} />}
      >
        <AllInvoices />
      </Authorization>
    ),
    name: 'menu.reports.all',
    allowedRoles: [ROLES.ADMIN],
  },
  {
    path: '/reports/monthly-hours-and-profitability',
    element: (
      <Authorization
        allowedRoles={[ROLES.ADMIN]}
        allowedEmployeeTypes={[]}
        forbiddenFallback={<Navigate to={RoutesCollection.ForbiddenPage} />}
      >
        <Profitability />
      </Authorization>
    ),
    name: 'menu.reports.profitability',
    allowedRoles: [ROLES.ADMIN],
  },
  {
    path: '/reports/partially-allocated',
    element: (
      <Authorization
        allowedRoles={[ROLES.ADMIN]}
        allowedEmployeeTypes={[]}
        forbiddenFallback={<Navigate to={RoutesCollection.ForbiddenPage} />}
      >
        <PartiallyAllocated />
      </Authorization>
    ),
    name: 'menu.reports.partiallyAllocated',
    allowedRoles: [ROLES.ADMIN],
  },
];

export const ReportsRoutes = () => {
  return (
    <>
      <Outlet />
    </>
  );
};
