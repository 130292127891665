import { ContentLayout } from '@/components/Layout';
import { useTranslation } from 'react-i18next';
import { useAuthorization, ROLES, Authorization } from '@/features/authorization';
import { useAuth } from '@/features/auth';
import { RoutesCollection, type TRouteObjectWithName } from '@/config/routing';
import { lazyImport } from '@/lib/lazyImport';
import { Navigate, Outlet, useMatch, useNavigate } from 'react-router-dom';
import { Tabs } from '@/components/Navigation';
import useEffectOnce from '@/hooks/useEffectOnce';

const { ActivityPlanningEmployee } = lazyImport(() => import('./ActivityPlanningEmployee'), 'ActivityPlanningEmployee');
const { ActivityPlanningManager } = lazyImport(() => import('./ActivityPlanningManager'), 'ActivityPlanningManager');

export const activityPlanningRoutes: TRouteObjectWithName[] = [
  {
    name: 'planning.tab.myActivityPlanning',
    path: RoutesCollection.ActivityPlanningEmployee,
    element: <ActivityPlanningEmployee />,
  },
  {
    name: 'planning.tab.manageActivityPlanning',
    path: RoutesCollection.ActivityPlanningManager,
    element: (
      <Authorization
        allowedRoles={[ROLES.MANAGER, ROLES.ADMIN]}
        allowedEmployeeTypes={[]}
        forbiddenFallback={<Navigate to={RoutesCollection.ForbiddenPage} />}
      >
        <ActivityPlanningManager />
      </Authorization>
    ),
    allowedRoles: [ROLES.MANAGER, ROLES.ADMIN],
  },
];

const TabsSection = ({ routes }: { routes: TRouteObjectWithName[] }) => {
  return (
    <Tabs
      routes={routes}
      onRouteChange={() => {
        window.scrollTo(0, 0);
      }}
    />
  );
};

export const ActivityPlanningRoutes = () => {
  const { t } = useTranslation(['activityPlanning', 'common']);
  const { profile } = useAuth();
  const navigate = useNavigate();
  const { checkAccess } = useAuthorization();
  const matchRoute = useMatch(RoutesCollection.ActivityPlanning);
  const matchManager = useMatch(RoutesCollection.ActivityPlanningManager);

  useEffectOnce(() => {
    if (!matchManager) {
      navigate(RoutesCollection.ActivityPlanningEmployee);
    }
  });

  if (matchRoute) {
    navigate(profile?.isManager ? RoutesCollection.ActivityPlanningManager : RoutesCollection.ActivityPlanningEmployee);
  }

  return (
    <ContentLayout
      title={t('activityPlanning.title', {
        role: checkAccess({ allowedRoles: [ROLES.MANAGER], allowedEmployeeTypes: [] })
          ? t('common:manager')
          : t('common:employee'),
      })}
    >
      <section className="flex flex-col gap-6 m-0 mt-11">
        <TabsSection routes={activityPlanningRoutes} />
        <Outlet />
      </section>
    </ContentLayout>
  );
};
