import { monthNames } from '@/config/constants';
import {
  format,
  lastDayOfYear,
  startOfYear,
  lastDayOfMonth,
  startOfMonth,
  min,
  addYears,
  subYears,
  setMonth,
  startOfWeek,
  subWeeks,
  addDays,
  subDays,
} from 'date-fns';

export function getMonthName(monthNumber: number) {
  const date = new Date();
  date.setDate(1);
  date.setMonth(monthNumber - 1);

  return monthNames[date.toLocaleDateString('default', { month: 'long' }).toLocaleLowerCase()];
}

export const getMonthBoundaryDates = (date: Date): { startDate: string; endDate: string } => {
  const firstDay = startOfMonth(date);
  let lastDay = lastDayOfMonth(date);

  const today = new Date();
  lastDay = min([lastDay, today]);

  return {
    startDate: format(firstDay, 'yyyy-MM-dd'),
    endDate: format(lastDay, 'yyyy-MM-dd'),
  };
};

export const getFirstAndLastDayOfMonth = (date: Date): { startDate: string; endDate: string } => {
  const firstDay = startOfMonth(date);
  const lastDay = lastDayOfMonth(date);

  return {
    startDate: format(firstDay, 'yyyy-MM-dd'),
    endDate: format(lastDay, 'yyyy-MM-dd'),
  };
};

export const getFirstAndLastDayOfYear = (date: Date): { startDate: string; endDate: string } => {
  const firstDay = startOfYear(date);
  const lastDay = lastDayOfYear(date);

  return {
    startDate: format(firstDay, 'yyyy-MM-dd'),
    endDate: format(lastDay, 'yyyy-MM-dd'),
  };
};

export const getSchedulerRange = (date: Date): { startDate: string; endDate: string } => {
  const fromDate = startOfMonth(setMonth(subYears(date, 1), 11));
  const toDate = lastDayOfMonth(setMonth(addYears(date, 1), 0));

  return {
    startDate: format(fromDate, 'yyyy-MM-dd'),
    endDate: format(toDate, 'yyyy-MM-dd'),
  };
};

export const disableWeekends = (date: Date) => {
  if ([0, 6].includes(date.getDay())) {
    return true;
  }
  return false;
};

export const getCurrentAndLastWeekBounds = (today: Date) => {
  const firstDayOfThisWeek = startOfWeek(today, { weekStartsOn: 1 });
  const firstDayOfLastWeek = subWeeks(firstDayOfThisWeek, 1);
  const lastDayOfThisWeek = addDays(firstDayOfThisWeek, 4);
  const lastDayOfLastWeek = subDays(lastDayOfThisWeek, 7);

  return {
    currentWeek: {
      start: firstDayOfThisWeek,
      end: lastDayOfThisWeek,
    },
    lastWeek: {
      start: firstDayOfLastWeek,
      end: lastDayOfLastWeek,
    },
  };
};

export const getYYYYMMDD = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}/${month}/${day}`;
};
