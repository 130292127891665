import { PRODUCTION_URL, STAGING_URL } from '@/config/constants';
import { sentryConfig } from '@/config/sentryConfig';
import { useAuth } from '@/features/auth';
import { UserProfile } from '@/features/profile';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

export const SentryProvider = () => {
  const { profile } = useAuth();
  const getEnvironment = () => {
    switch (window.location.origin) {
      case PRODUCTION_URL:
        return 'prod';
      case STAGING_URL:
        return 'staging';
      default:
        return 'local';
    }
  };

  const setUserContext = (user: UserProfile | null) => {
    if (user === null) {
      return;
    }

    Sentry.setContext('character', { email: user.email, id: user.id, username: `${user.firstName} ${user.lastName}` });
  };

  useEffect(() => {
    if (window.location.href.includes('localhost')) {
      return;
    }

    Sentry.init({
      ...sentryConfig,
      environment: getEnvironment(),
    });

    setUserContext(profile);
  }, [profile]);

  return <></>;
};
