import { ContentLayout } from '@/components/Layout';
import { Slider } from '@mui/material';
import ChristmasWidget from '../components/widgets/ChristmasWidget';

export const Dashboard = () => {
  return (
    <ContentLayout title={''}>
      <ChristmasWidget />

      <section className="flex flex-col gap-8 mt-8">
        <Slider defaultValue={15} className="text-[#F7931E]" />
        <Slider defaultValue={30} className="text-[#000000]" />
        <Slider defaultValue={45} className="text-[#CE4F4F]" />
        <Slider defaultValue={60} className="text-[#44A04D]" />
        <Slider defaultValue={75} className="text-[#7C70FF]" />
      </section>
    </ContentLayout>
  );
};
