export default {
  'gettingStarted.title': 'Getting started',
  'gettingStarted.paragraphs': [
    'The Vacation Planner feature in Lynx Management Portal aims to provide a comprehensive tool for planning and managing time off for employees, contractors, and management within a single application. The goal is to enhance user experience, reduce errors due to manual processes and minimize HR workload.',
    'Please authenticate using Microsoft 365 credentials and browse the below topics for additional details. ',
  ],
  'section.title': 'Sections',
  'section.myTime.title': 'My Time',
  'section.myTime.paragraphs': [
    'Initiate, manage and track time off requests with complete details in one place, both list and Calendar view',
    'Toggle between Orthodox and Catholic national holidays (enabled yearly between 01 January - 15 March)',
  ],
  'section.myTime.notable':
    '*due to legal restrictions, Contractors have a simplified version of this feature, where they can only mark their days off, without any type of approval flow',
  'section.manageTimeOff.title': 'Manage Time Off',
  'section.manageTimeOff.paragraphs': [
    'View time off calendar for all employees under the responsibility of a Manager',
    'Access pending and past approved requests for better planning',
  ],
  'section.outOffOffice.title': 'Out Of Office List',
  'section.outOffOffice.paragraphs': ['List of persons with day off requests approved for current day'],
  'section.userGuide.title': 'User Guide',
  'approvalFlow.myTime.title': 'Approval Flow | My Time',
  'approvalFlow.myTime': {
    step1:
      'After initiating a request, it will appear under the <strong>Pending requests</strong> list view, as well as on the personal Calendar',
    step2:
      'After the request was reviewed by the Manager it will automatically move to the <strong>Reviewed requests</strong> list',
    step3:
      'Hovering over the icons near the items under the <strong>Reviewed requests</strong> shows additional information related to the status and name of the decision maker(s)',
    step4: 'When the request is approved by the Manager or HR, it is considered approved',
  },
  'approvalFlow.myTime.notable':
    '! In case the request wasn’t approved in time, it will be rejected automatically by the system in the name of the Manager, on its starting date !  - in such cases please get in contact with HR.',
  'approvalFlow.manageTimeOff.title': 'Approval Flow | Manage Time Off',
  'approvalFlow.manageTimeOff': {
    step1:
      'After the request was initiated by a person from the team, it will appear under the <strong>Pending requests</strong> list view, as well as on the team Calendar',
    step2: {
      title: 'Based on the type of absence, there is the possibility to review and act on the requests as follows:',
      substep1: 'Admit (ex. medical/ blood donation/ child birth/ etc.)',
      substep2: 'Approve/ Reject (ex. vacation/ vacation half day/ etc.)',
    },
    step3: 'After the request was reviewed, it will automatically move to the <strong>Reviewed requests</strong> list ',
  },
  'approvalFlow.manageTimeOff.notable':
    '! In case the request wasn’t approved in time, it will be rejected automatically by the system in the name of the Manager, on its starting date !',
  'responsibilities.title': 'Responsibilities',
  'responsibilities.employee': {
    title: 'Employee Responsibilities',
    rule1: 'Follow the on-screen instructions to initiate, manage and track time off requests efficiently',
    rule2:
      'Based on the type of absence, the modal will display a help text, please read it carefully before initiating the request',
    rule3:
      'When <strong>Attachment</strong> is requested make sure to attach it as soon as possible, you have the possibility to do so from each listing view, even after the request was approved',
    rule4:
      'By <strong>Attachments</strong> we are referring to the proof documents for the type of absence selected, all the other documents (ex. Cerere Concediu) are automatically generated by the system no need to attach them!',
    rule5: 'The signature will be replaced by the below statement!',
    rule6:
      'When getting closer to the starting date of the requested time off and the request is still not approved, get in contact with the Manager, otherwise it will be automatically rejected',
  },
  'responsibilities.manager': {
    title: 'Manager Responsibilities',
    rule1:
      'Follow the on-screen instructions to review, admit/ approve/ reject and track time off requests efficiently',
    rule2: 'When rejecting a request mention the reason for doing so',
  },
  'responsibilities.hr': {
    title: 'HR Responsibilities',
    rule1:
      'Follow the on-screen instructions to review, admit/ approve/ reject and track time off requests efficiently',
    rule2: 'When rejecting a request mention the reason for doing so',
    rule3: 'Create events in the name of employee only in agreement with the involved persons',
  },

  'administrativeRules.title': 'Administrative Rules',
  'administrativeRules.employees': {
    title: 'Employees',
    vacation: {
      title: '<strong>Vacation</strong> requests should be submitted as follows:',
      paragraphs: [
        'For 1 day of leave, the request must be submitted no later than 1 day before the start date.',
        'For 2-5 days of leave, the request must be submitted at least 4-10 days in advance.',
        'For 5-10 days, the request must be submitted at least 10-20 days in advance.',
      ],
    },
    halfDay: '<strong>Vacation - Before Noon/ Afternoon</strong> requests can be submitted on the day of leave.',
    medicalLeave: {
      title: '<strong>Medical Leave</strong>',
      paragraphs: [
        'Requests should be submitted on the first day of illness, and the medical certificate must be attached no later than 4-5 calendar days from the first day of illness.',
        'Medical certificates should be scanned and sent to {{hrEmail}}.',
        'Original medical certificates (white + pink copy) must be sent within a maximum of 4-5 days from the date of issuance, via Fan Courier, to the workplace in {{mailingInfo}}.',
      ],
    },
    marriage: '<strong>Marriage</strong> (5 days) request should be submitted along with supporting documents.',
    childBirth: '<strong>Child Birth</strong> (10 days) request should be submitted along with supporting documents.',
    newbornCare: '<strong>Newborn Care</strong> (5 days) request should be submitted along with supporting documents.',
    bereavementLeave: {
      title:
        '<strong>Bereavement Leave</strong> request should be submitted along with supporting documents, and the number of days granted is related to the degree of kinship as follows:',
      paragraphs: [
        '1<sup>st</sup> Degree (3 days) - spouse, children, parents, in-laws.',
        '2<sup>nd</sup> Degree (1 days) - grandparents, siblings.',
      ],
    },
    bloodDonation:
      '<strong>Blood Donation</strong> (1 day) request should be submitted along with supporting documents.',
    unpaidLeave:
      '<strong>Unpaid Leave</strong> request should be submitted at least 5 working days before the first day of unpaid leave and only after prior approval from the direct manager and HR manager.',
    businessTrip:
      '<strong>Business Trip</strong> request should be submitted at least 1-2 days before the first day of the trip, after notification to the HR department and assistant manager for the travel order preparation.',
    tips: {
      title: 'Good to know:',
      paragraphs: ['Requests cannot be registered retroactively, in such situations, please contact HR.'],
    },
  },
  'administrativeRules.managers': {
    title: 'Managers',
    paragraphs: [
      'Requests by employees must be approved by you by the day prior to the first day of leave; otherwise, the requests will be automatically refused by the system.',
      'If you have not managed to approve the requests in time, please contact HR.',
    ],
  },
  'administrativeRules.footer':
    'Any missing documents will result in the non-approval of requests and your absence being recorded.',

  'enable.all': 'enabled for all',
  'enable.manager': 'enabled for managers',
  'pending.days.rule':
    "! When the request is approved only by the Manager, the card with 'Used vacation days' is not yet re-calculated, only when it receives the final approval by HR.",
};
