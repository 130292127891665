import React, { useEffect, useRef } from 'react';

const ChristmasWidget = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    const resizeCanvas = () => {
      canvas.width = canvas.offsetWidth * window.devicePixelRatio;
      canvas.height = canvas.offsetHeight * window.devicePixelRatio;
    };

    resizeCanvas();

    const ctx = canvas.getContext('2d');
    const snowflakes = Array.from({ length: 50 }, () => ({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      r: Math.random() * 4,
      d: Math.random() * 2,
    }));

    const updateSnowflakes = () => {
      if (!ctx) {
        return;
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      snowflakes.forEach((flake) => {
        ctx.beginPath();
        ctx.arc(flake.x, flake.y, flake.r, 0, Math.PI * 2);
        ctx.fillStyle = 'white';
        ctx.fill();
        flake.y += flake.d;
        if (flake.y > canvas.height) {
          flake.y = 0;
          flake.x = Math.random() * canvas.width;
        }
      });
      requestAnimationFrame(updateSnowflakes);
    };

    updateSnowflakes();
  }, []);

  return (
    <div className="relative w-full max-h-[200px] bg-red-500 text-white flex items-center justify-center rounded-lg shadow-lg overflow-hidden select-none">
      <canvas
        ref={canvasRef}
        className="absolute top-0 left-0 w-full h-full"
        style={{ pointerEvents: 'none' }}
      ></canvas>
      <div className="text-center z-10 bg-red-500 px-4 bg-opacity-80">
        <h1 className="text-2xl font-bold mb-2">🎄 Merry Christmas! 🎅</h1>
        <p className="text-sm">Wishing you a holiday season filled with joy, love, and laughter.</p>
      </div>
    </div>
  );
};

export default ChristmasWidget;
